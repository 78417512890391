<template>
    <div v-if="accounts !== undefined && (minimumCount === null || Object.keys(accounts).length >= minimumCount)">
        <div class="card">
            <div class="card-body d-flex flex-column flex-lg-row">
                <button
                    v-for="account in accounts"
                    class="m-1 btn btn-outline-primary border-0"
                    :disabled="$user() && account.account.username === $user().username"
                    @click="switchAccount(account.token)"
                >
                    <Avatar :user="account.account" dsize="3rem" />
                    <p class="mb-0">
                        @{{ account.account.username }}
                    </p>
                </button>
                <button
                    v-if="Object.keys(accounts).length < 5"
                    class="m-1 btn btn-outline-primary border-0 btn-sm"
                    @click="addAccount"
                >
                    <Icon v="plus-circle" :size="3" />
                    <p class="mb-0">
                        <T>user.accountSwitch.add</T>
                    </p>
                </button>
            </div>
        </div>
        <p v-if="helper" class="text-muted small">
            <T>user.accountSwitch.helper</T>
        </p>
    </div>
</template>

<script>
import { mapState } from 'pinia';
import * as Sentry from '@sentry/vue';
import useConfig from '../composables/useConfig.ts';
import { useMainStore } from '../store/index.ts';

export default {
    props: {
        minimumCount: { type: Number, default: null },
        helper: { type: Boolean },
    },
    setup() {
        return {
            config: useConfig(),
        };
    },
    computed: {
        ...mapState(useMainStore, [
            'accounts',
        ]),
    },
    mounted() {
        this.$accounts();

        // just in case…
        setTimeout(this.validateAccounts, 1000);
        setInterval(this.validateAccounts, 60000);
    },
    methods: {
        switchAccount(token) {
            this.$setToken(token);
            setTimeout(() => window.location.reload(), 300);
        },
        addAccount() {
            this.$setToken(null);
            this.$router.push(`/${this.config.user.route}`);
            setTimeout(() => window.location.reload(), 300);
        },
        async validateAccounts() {
            for (const [username, { token }] of Object.entries(this.accounts)) {
                try {
                    const user = await $fetch('/api/user/current?no_cookie', {
                        headers: {
                            authorization: `Bearer ${token}`,
                        },
                    });
                    if (!user || user.username !== username) {
                        this.$removeToken(username);
                    }
                } catch (error) {
                    Sentry.captureException(error);
                    this.$removeToken(username);
                }
            }
        },
    },
};
</script>
